














import { OpenIbanResponse } from '@/typings/OpenIban'
import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import axios from 'axios'
import { removeSpaces } from '@/functions/stringFunctions'

@Component
class IbanField extends Vue {
  @Prop(String) readonly value!: string
  openIbanValidation: OpenIbanResponse | null = null
  isLoading = false
  rulesIban = [this.checkIfIbanValid]
  errorMessages = ''
  bankAccountIban = ''

  mounted() {
    this.bankAccountIban = this.value
  }

  checkIfIbanValid(value = '') {
    const trimmedValues = removeSpaces(value)
    if (
      trimmedValues &&
      trimmedValues.length > 0 &&
      trimmedValues.length < 16
    ) {
      return 'IBAN is invalid, needs at least 16 characters'
    }
    return true
  }

  get hintBankAccount() {
    if (
      this.bankAccountIban &&
      this.openIbanValidation &&
      this.openIbanValidation.bankData
    ) {
      return `This bank account is from ${this.openIbanValidation?.bankData.name}`
    }
    return ''
  }

  handleIbanCheck() {
    if (
      this.bankAccountIban &&
      removeSpaces(this.bankAccountIban).length > 15
    ) {
      this.isLoading = true
      const ibanWithoutSpaces = removeSpaces(this.bankAccountIban)
      axios
        .get<OpenIbanResponse>(
          `https://openiban.com/validate/${ibanWithoutSpaces}?getBIC=true&validateBankCode=true`
        )
        .then((responseJson) => {
          this.openIbanValidation = {
            valid: responseJson.data.valid,
            bankData: responseJson.data.bankData
          }
          this.isLoading = false
          this.handleErrorMessages()
        })
    }
    if (this.bankAccountIban.length === 0) {
      this.errorMessages = ''
    }
  }

  handleErrorMessages() {
    if (this.openIbanValidation && !this.openIbanValidation.valid) {
      this.errorMessages = 'IBAN number is invalid'
    } else {
      this.errorMessages = ''
      this.$emit('updateBankAccountIban', {
        bankAccountIban: this.bankAccountIban,
        bankAccountBic: this.openIbanValidation?.bankData.bic
      })
    }
  }
}

export default IbanField
