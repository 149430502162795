
























































































































































































































































import RequestApi from '@/services/RequestApi'
import Vue from 'vue'
import { Ref, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { isRequired } from '@bluecorner/shared'
import { IsAttachmentType, IsSizeAttachment, IsFileName } from '@/functions/formRules'
import VForm from '@/typings/Vuetify/VForm'
import GenericTemplate from './GenericTemplate.vue'
import {
  defaultTemplateData,
  JiraFieldTypeApi,
  JiraRequestField,
  JiraRequestFieldApi,
  JiraRequestType,
  PayloadType,
  TemplateItem,
  ValidValues
} from '@/typings/ApiTypings/JiraSupportInterface'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
const requestField = {
  SUB_REQUEST: 'sub',
  REQUEST: 'req'
}
@Component({
  components: {
    GenericTemplate
  }
})
export default class GenericSupport extends Vue {
  @Ref() readonly form!: VForm
  attachementTab = []
  show = false
  serviceCategoryTab: JiraRequestField | null = null
  requestTypeTab: ValidValues[] = []
  requestTypeTabOriginale: JiraRequestField | null = null
  subRequestTab: ValidValues[] = []
  subRequestTabOriginale: JiraRequestField | null = null
  serviceCategory: ValidValues | null = null
  requestType: ValidValues | null = null
  subRequestType: ValidValues | null = null
  isSaved = false
  isSaveRequest = false
  isSaving = false

  payload = {
    summary: '',
    description: '',
    customfield_13200: '',
    attachment: []
  }

  isReimbursement = false
  isKnownPortal = false

  templateData = defaultTemplateData()

  error = false
  errorProvider = false
  isFormValid = true
  requestTitle = {
    text: '$vuetify.serviceCenter.haveQuestion',
    color: 'green darken-2',
    icon: '$vuetify.icons.help'
  }

  rules = {
    required: isRequired(),
    attachmentType: IsAttachmentType(
      'This filetype is not supported, please upload one of the following filetypes: pdf, docx, gif, jpeg, png'
    ),
    fileName: IsFileName(
    'Invalid filename. Please choose another file.'
    ),
    attachmentSize: IsSizeAttachment(
      'These files are too big. Max. file total size is  5 MB'
    )
  }

  requestList: JiraRequestType[] = []
  fieldList: JiraRequestField[] = []
  fieldFilterList: JiraRequestField[] = []
  requestId = ''
  requestModel = []
  isLoading = false

  mounted() {
    this.getRequestList()
  }

  async getRequestList() {
    try {
      this.isLoading = true
      const res = (await new RequestApi().getRequestList()) as JiraFieldTypeApi
      const searchVal: JiraRequestType[] = []
      for (const element of res.result.data) {
        if (element.name === 'Portal Request') {
          this.requestId = element.id
          searchVal.push(element)
        }
      }
      this.requestList = searchVal
      this.getFieldRequest(this.requestId)
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.log(error)
    }
  }

  nextStep() {
    this.submit()
  }

  submit() {
    if (this.form.validate()) {
      this.isSaving = true
      this.isSaveRequest = true
      this.save()
    }
  }

  async save() {
    try {
      if (this.serviceCategoryTab && this.requestTypeTabOriginale) {
        const payload: PayloadType = {
          [this.serviceCategoryTab.fieldid]: [
            { key: this.serviceCategory?.objectKey }
          ],
          [this.requestTypeTabOriginale.fieldid]: [
            { key: this.requestType?.objectKey }
          ]
        }

        if (this.subRequestTabOriginale) {
          payload[this.subRequestTabOriginale.fieldid] = [
            { key: this.subRequestType?.objectKey }
          ]
        }

        for (const element of this.fieldFilterList) {
          if (
            element.fieldid === 'summary' ||
            element.fieldid === 'description'
          ) {
            if (
              this.requestType?.label === 'Bestuurderswissel' &&
              element.fieldid === 'description'
            ) {
              payload[element.fieldid] = ''
              this.templateData.forEach((tab) => {
                if (tab.showCheckbox) {
                  payload[element.fieldid] += `\n${tab.checkboxLabel}\n`
                }

                if (!tab.visibility) {
                  payload[element.fieldid] += 'N/A \n'
                } else {
                  tab.template.forEach((item: TemplateItem) => {
                    if (item.type !== 'title') {
                      payload[element.fieldid] += `${item.label}${item.data}\n`
                    } else {
                      payload[element.fieldid] += item.label
                    }
                  })
                }
              })
            } else {
              payload[element.fieldid] = element.dta
            }
          } else {
            payload[element.fieldid] = [{ key: element.dta }]
          }
        }
        try {
          const res = (await new RequestApi().createFromType(
            this.requestId,
            payload
          )) as DefaultResponseInterface
          if (this.attachementTab.length > 0) {
            try {
              const requestId = res.result.data.id
              const formData: FormData = new FormData()
              this.attachementTab.forEach((attachment) => {
                formData.append('files', attachment)
              })
              await new RequestApi().addAttachment(requestId, formData)
              if (!this._.isEmpty(res.result.data.Status)) {
                this.show = true
              } else {
                this.$emit('refresh')
              }
            } catch (error) {
              this.notification({
                content:
                  'We encountered an issue that prevented us from adding attachments to tickets.',
                color: 'error',
                position: 'right-top'
              })
            }

            this.isSaving = false
            this.isSaveRequest = false
          } else {
            if (!this._.isEmpty(res.result.data.Status)) {
              this.show = true
            } else {
              this.$emit('refresh')
            }
          }
        } catch (error) {
          this.notification({
            content: 'Error',
            color: 'error',
            position: 'right-top'
          })
          this.isSaving = false
          this.isSaveRequest = false
        }
      }
      this.isSaving = false
      this.isSaveRequest = false
    } catch (error) {
      this.notification({
        content: 'Error',
        color: 'error',
        position: 'right-top'
      })
      this.isSaving = false
      this.isSaveRequest = false
    }
  }

  previousStep() {
    this.cleanPayload()
    this.$emit('back')
  }

  get supportAttachmentFlag() {
    return this.$store?.state?.flagsmith?.featureFlags
      .generic_support_attachments
  }

  get supportDriverTemplateFlag() {
    return this.$store?.state?.flagsmith?.featureFlags
      .generic_support_driver_template
  }

  closeRQ() {
    this.cleanPayload()
    this.$emit('closeRQ')
  }

  cancel() {
    this.cleanPayload()
    this.$emit('cancel')
  }

  cleanPayload() {
    this.payload = {
      summary: '',
      description: '',
      customfield_13200: '',
      attachment: []
    }
  }

  async getFieldRequest(val: string) {
    if (val !== undefined) {
      try {
        this.requestModel = []
        const res = (await new RequestApi().getFieldList(
          val
        )) as JiraRequestFieldApi
        this.fieldList = res.result.data
        for (const element of this.fieldList) {
          switch (element.name) {
            case 'Service Category':
              this.serviceCategoryTab = element
              break
            case 'Request Type':
              this.requestTypeTabOriginale = element
              break
            case 'Request Subtype':
              this.subRequestTabOriginale = element
              break
            default:
              this.fieldFilterList.push(element)
              break
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  preFieldRequest(val: string) {
    this.getFieldRequest(val)
  }

  filterRequestType(tab: ValidValues[], type: string, label: string) {
    const tabFilter: ValidValues[] = []
    for (const element of tab) {
      if (element.linkedvalue === label && element.label !== 'No action') {
        tabFilter.push(element)
      }
    }
    if (type === 'req') {
      this.requestTypeTab = tabFilter
    } else {
      this.subRequestTab = tabFilter
    }
  }

  get currentLang() {
    return this.$vuetify.lang.current.toUpperCase()
  }

  @Watch('serviceCategory')
  serviceCategoryOnChanged() {
    if (this.requestTypeTabOriginale && this.serviceCategory) {
      this.subRequestType = null
      this.filterRequestType(
        this.requestTypeTabOriginale.validvalues,
        requestField.REQUEST,
        this.serviceCategory.label
      )
    }
  }

  @Watch('requestType')
  requestTypeOnChanged() {
    if (this.subRequestTabOriginale && this.requestType) {
      this.subRequestType = null
      this.filterRequestType(
        this.subRequestTabOriginale.validvalues,
        requestField.SUB_REQUEST,
        this.requestType.label
      )
    }
  }
}
