



























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import GenericTablePaginationJira from '../../components/_base/BcDataTable/GenericTablePaginationJira.vue'
import PaginationInterface from '../../typings/PaginationInterface'
import RequestApi from '../../services/RequestApi'
import moment from 'moment'
import GenericSupport from './requests/GenericSupport.vue'
import ChargePassRequest from './requests/ChargePassRequest.vue'
import AutocompleteStateRequest from '../../components/sessions/AutocompleteStateRequest.vue'
import { mapGetters } from 'vuex'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { DataTableHeader } from 'vuetify'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import {
  RelationFilterInterface,
  RelationTypes
} from '@/typings/RelationFilterInterface'
import { FilterInterface } from '@/typings/FilterOptions'
import { GENERIC_FILTER_CHARGEPASS_REQUEST } from '@/mocks/featureFlags'
import { PayloadInterface } from '@/typings/OptionsDataTable'
import { stat } from 'fs'

interface ChargePassRequestInterface {
  id: string
  type: string
  key: string
  summary: string
  attachment?: number
  status: string
  requester: string
  created: string
}

enum ChargePassRequestStates {
  pending = 'PENDING',
  accepted = 'ACCEPTED',
  done = 'Gedaan',
  todo = 'In Uitvoering'
}

@Component({
  components: {
    GenericTablePaginationJira,
    AutocompleteStateRequest,
    GenericSupport,
    ChargePassRequest
  },
  filters: {
    formatLongText (val: string) {
      return val && val !== '' ? val : ' - '
    }
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class MyRequests extends Vue {
  @Prop({ type: String, default: '0' }) readonly id!: string
  genericRelationFilter!: RelationFilterInterface
  search = ''
  issueType = 0
  page = 1
  current = 1
  pageCount = 0
  headers: DataTableHeader[] = [
    {
      text: this.t('$vuetify.serviceCenter.type'),
      value: 'type',
      sortable: false,
      width: '10%'
    },
    {
      text: this.t('$vuetify.serviceCenter.reference'),
      value: 'reference',
      sortable: false,
      width: '10%'
    },
    {
      text: this.t('$vuetify.serviceCenter.summary'),
      value: 'summary',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.serviceCenter.status'),
      value: 'status',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.serviceCenter.requester'),
      value: 'owner',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.serviceCenter.created'),
      value: 'date',
      sortable: false,
      width: '20%'
    }
  ]

  payload = {
    summary: '',
    description: '',
    descriptionBis: '',
    issueType: 0,
    about: '',
    newAddress: '',
    charger: '',
    chargepass: '',
    urgency: ''
  }

  itemIssues = [
    { val: 0, txt: this.t('$vuetify.serviceCenter.createdByMe') },
    { val: 1, txt: this.t('$vuetify.serviceCenter.createdByAnyone') }
  ]

  loading = false
  dialog = false
  dialog2 = false
  selectedItem = -1

  data: ChargePassRequestInterface[] = []
  selected = 0
  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  mounted () {
    this.initPage()
  }

  getColorStatus (status: string) {
    if (status === 'Done') {
      return 'primary'
    }
    if (status === 'Open') {
      return '#BBDEFB'
    }
    if (status === 'REJECTED') {
      return '#FF5252'
    }
  }

  getDetailPage (item: ChargePassRequestInterface) {
    if (item.type === 'Charge Pass') {
      this.$router.push({ name: 'requestCPDetails', params: { id: item.id } })
    } else {
      this.$router.push({ name: 'requestDetails', params: { id: item.id } })
    }
  }

  cancel () {
    this.dialog2 = false
    this.selectedItem = -1
  }

  closeRQ () {
    this.initPage()
    this.cancel()
  }

  next () {
    this.current++
  }

  refresh () {
    this.initPage()
    this.dialog2 = false
    this.selectedItem = -1
  }

  async initPage () {
    this.loading = true
    const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
      store: this.$store,
      route: this.$route,
      path: '/my/requests',
      flag: GENERIC_FILTER_CHARGEPASS_REQUEST
    })
    try {
      this.data = []
      this.loading = true
      const payload: PayloadInterface = {
        pagesize: 500,
        pagenumber: 1,
        filter: {},
        relationContactId: undefined,
        relationId: undefined
      }
      let filter: FilterInterface = {}
      let relationContactId
      let relationId
      if (isAllowed) {
        const selectedRelation = this.genericRelationFilter
        if (
          selectedRelation &&
          selectedRelation.Type === RelationTypes.relationContact
        ) {
          payload.relationContactId = selectedRelation.Id
          relationContactId = selectedRelation.Id
        } else if(selectedRelation.Id) {
          payload.relationId = selectedRelation.Id
          relationId = selectedRelation.Id
        }
      }
      if (
        this.search !== '' &&
        this.search !== 'tmp' &&
        this.search !== undefined &&
        this.search !== null
      ) {
        if (this.search === 'To Do') {
          payload.filter =  { and: [ {Status: { op: 'NE', value: 'Done' }}, {Status: { op: 'NE', value: 'Gedaan' }}]}
        } else {
          payload.filter = { or: [ {Status: "Done"}, {Status: "Gedaan"}]}
        }
      }
      let dt
      if (!this.issueType) {
        dt = (await new RequestApi().myIssues(
          this.id,
          payload,
          this.pagination
        )) as DefaultResponseInterface
      } else {
        dt = (await new RequestApi().myOrganizationIssues(
          this.id,
          payload
        )) as DefaultResponseInterface
      }
      this.data = []
      if (dt) {
        const fetchDTA = dt.data.records
        for (const element of fetchDTA) {
          const elm: ChargePassRequestInterface = {
            id: element.ID,
            type: element.Type,
            key: element.SourceReference,
            summary: element.Title,
            status: element.Status,
            attachment: element.AttachmentCount,
            requester: element.contact.name,
            created: moment(element.CreatedOn).format('DD/MM/YYYY HH:mm:ss')
          }
          if (elm.status === ChargePassRequestStates.pending || elm.status === ChargePassRequestStates.todo) {
            elm.status = 'Open'
          }
          if (elm.status === ChargePassRequestStates.accepted || elm.status === ChargePassRequestStates.done) {
            elm.status = 'Done'
          }
          this.data.push(elm)
        }
      }
      try {
        if (this.search === 'To Do') {
          filter = { or: [ {state: "PENDING"}, {state: "In Uitvoering"}]}
        } else if (this.search === 'Done') {
          filter = { state: ChargePassRequestStates.accepted }
        } else if (this.search === 'In Progress') {
          filter = { state: ChargePassRequestStates.pending }
        }
        const isOwner = this.issueType !== 0
        this.pagination = {
          pagesize: 5000,
          pagenumber: -1,
          type: 0
        }
        const dtChargerReq = (await new RequestApi().myChargerReq({
          pagination: this.pagination,
          filter,
          isOwner,
          relationContactId,
          relationId
        })) as DefaultResponseInterface
        const fetchDTA = dtChargerReq.data.records
        for (const element of fetchDTA) {
          const elm = {
            id: element.ID,
            type: 'Charge Pass',
            key: 'CRRQ-' + element.ID,
            summary: 'Chargepass Request',
            status: element.State,
            requester: element.RelationContactDesc,
            created: moment(element.CreatedOn).format('DD/MM/YYYY HH:mm:ss')
          }
          
          if (elm.status === ChargePassRequestStates.pending || elm.status === ChargePassRequestStates.todo) {
            elm.status = 'Open'
          }
          if (elm.status === ChargePassRequestStates.accepted || elm.status === ChargePassRequestStates.done) {
            elm.status = { or: [ {Status: "Done"}, {Status: "Gedaan"}]}
          }
          this.data.push(elm)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
      this.loading = false
    } catch (err) {
      console.log(err)
    }
    this.data.sort((a, b) =>
      moment(a.created, 'DD-MM-YYYY HH:mm:ss').isAfter(
        moment(b.created, 'DD-MM-YYYY HH:mm:ss')
      )
        ? -1
        : 1
    )
  }

  get requestTitle () {
    let lbl = {
      text: 'Other Request.',
      color: 'grey darken-2',
      icon: '$vuetify.icons.other'
    }
    switch (this.selectedItem) {
      case 0:
        lbl = {
          text: 'I want to report a problem with a charger or with a charge pass.',
          color: 'yellow darken-2',
          icon: '$vuetify.icons.cardQuestionMark'
        }
        break
      case 1:
        lbl = {
          text: 'I have a question.',
          color: 'green darken-2',
          icon: '$vuetify.icons.help'
        }
        break
      case 2:
        lbl = {
          text: 'I am moving.',
          color: 'red darken-1',
          icon: '$vuetify.icons.moving'
        }
        break
      case 3:
        lbl = {
          text: 'I have a complaint.',
          color: 'purple darken-2',
          icon: '$vuetify.icons.complaint'
        }
        break
      case 4:
        lbl = {
          text: 'I want to request a new charger installation.',
          color: 'red lighten-3',
          icon: '$vuetify.icons.newInstallation'
        }
        break
    }
    return lbl
  }

  @Watch('selectedItem')
  selectedItemOnChanged (val: number) {
    if (val === -1) {
      this.payload = {
        descriptionBis: '',
        summary: '',
        description: '',
        issueType: 0,
        about: '',
        newAddress: '',
        charger: '',
        chargepass: '',
        urgency: ''
      }
    }
  }

  @Watch('search')
  searchOnChanged () {
    this.initPage()
  }

  @Watch('issueType')
  issueTypeOnChanged () {
    this.initPage()
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged () {
    this.initPage()
  }
}
