


































































































































































































































































import Vue from 'vue'
import RelationContactApi from '@/services/RelationContactApi'
import RequestApi from '../../../services/RequestApi'
import { Watch, Prop, Ref } from 'vue-property-decorator'
import Component from 'vue-class-component'
import BcCountrySelect from '@/components/_base/BcCountrySelect'
import { RelationApi, RelationInterface } from '@/services/RelationApi'
import {
  NodeRelation,
  RelationTreeInterface,
  RelationTreeApiInterface,
  CompanyAddressInterface,
  RelationContactInterface,
  RelationContactApiInterface,
  CompanyApiAddressInterface,
  ChargePassRequestInterface,
  RelationTreeViewInterface
} from '@/typings/RequestInterface'
import { isRequired } from '@bluecorner/shared'
import { AddressInterface } from '@/typings/Address'

const INITIAL_COMPANY_ADDRESS_VALUE = {
  Street: '',
  HouseNumber: '',
  PostalCode: '',
  City: '',
  Country: ''
}

const INITIAL_DRIVER_ADDRESS_VALUE = {
  street: '',
  housenumber: '',
  postalcode: '',
  city: '',
  country: ''
}

@Component({
  components: {
    BcCountrySelect
  }
})
export default class ChargePassRequest extends Vue {
  @Prop({ type: Object }) readonly usrDTA!: RelationInterface
  @Ref() readonly form!: HTMLFormElement

  INITIAL_PAYLOAD = {
    summary: 'Charge Pass Request',
    description: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    mailing: false,
    country: '',
    products: [],
    orderQuantity: '',
    lang: ''
  }

  isSaved = false
  relationCompany: number | string = ''
  items: Array<RelationTreeInterface> = []
  relationContactId: number | string = ''
  userTab: Array<RelationContactInterface> = []
  isSaveRequest = false
  isSaving = false
  isOtherAddress = true
  driverAdr: AddressInterface = INITIAL_DRIVER_ADDRESS_VALUE
  companyAddress: CompanyAddressInterface = INITIAL_COMPANY_ADDRESS_VALUE

  payload: ChargePassRequestInterface = this.INITIAL_PAYLOAD

  isLoadingTree = false
  isLoading = false
  isFormValid = true
  error = false
  requestTitle = {
    text: '$vuetify.serviceCenter.requestChargeCard',
    color: 'green darken-2',
    icon: '$vuetify.icons.cardQuestionMark'
  }

  rules = {
    required: isRequired('Required'),
    numberRule: (v: string) => {
      if (!v.trim()) return true
      if (!isNaN(parseFloat(v)) && parseFloat(v) >= 0) return true
      return 'Order quantity should be a number'
    },
    numberMin: (v: number) => {
      if (v >= 1) return true
      return 'You can minimu order 1'
    },
    numberMax: (v: number) => {
      if (v <= 10) return true
      return 'You can maximum order 10'
    },
    zipCode: (v: string) => {
      if (!v.trim()) return true
      if (!isNaN(parseFloat(v)) && parseFloat(v) >= 0 && parseFloat(v) <= 9999)
        return true
      return 'Number has to be between 0 and 9999'
    },
    onlyNumber: (v: string) => {
      if (/^\d*$/.test(v)) {
        return true
      }
      return "Decimal number aren't allowed"
    }
  }

  mounted() {
    this.getTree()
  }

  async getTree() {
    this.isLoadingTree = true
    const dt: RelationTreeApiInterface = (await new RelationApi(
      0
    ).relationAllTree(true, '')) as RelationTreeApiInterface
    this.isLoadingTree = false
    if (dt && dt.data) {
      const formatTreeTab: RelationTreeInterface | undefined = this.formatTree(
        dt.data
      )
      if (formatTreeTab) {
        this.items = [formatTreeTab]
      }
    }
  }

  formatTree(object: RelationTreeViewInterface) {
    if (object) {
      const obj = object.parent
      if (obj && obj.children) {
        delete object.parent
        obj.children = []
        obj.children = obj.children.concat(object.relations)
      }
      return obj
    }
  }

  checkIfEmpty(elmObj: CompanyAddressInterface | AddressInterface) {
    for (const key in elmObj) {
      if (elmObj[key as keyof typeof elmObj] === '') {
        return true
      }
    }
    return false
  }

  normalizer(node: NodeRelation) {
    return {
      id: node.id,
      label: node.description,
      children: node.children
    }
  }

  submit() {
    if ((this.checkAddress || !this.isOtherAddress) && this.form.validate()) {
      if (this.isOtherAddress) {
        if (
          this.relationContactId === '' &&
          !this.checkIfEmpty(this.companyAddress)
        ) {
          this.save()
        } else if (
          (this.relationContactId !== '' || !this.checkIfAllowed()) &&
          !this.checkIfEmpty(this.driverAdr)
        ) {
          this.save()
        }
      } else {
        this.save()
      }
    }
  }

  closeRQ() {
    this.cleanPayload()
    this.$emit('closeRQ')
  }

  cleanPayload() {
    this.payload = this.INITIAL_PAYLOAD
  }

  async save() {
    try {
      if (this.payload && this.payload.orderQuantity) {
        const payload = {
          street: this.payload.street,
          housenumber: this.payload.housenumber,
          postalcode: this.payload.postalcode,
          description: this.payload.description,
          city: this.payload.city + ', ' + this.payload.country,
          mailing: false,
          country: this.payload.country,
          lang: this.payload.lang,
          products: [
            {
              saleschannelproductid: 5,
              amount: parseInt(this.payload.orderQuantity)
            }
          ],
          relationcontactid: this.relationContactId,
          relationid: this.relationCompany
        }

        if (this.isOtherAddress) {
          if (this.relationContactId === '' && this.checkIfAllowed()) {
            payload.street = this.companyAddress.Street
            payload.housenumber = this.companyAddress.HouseNumber
            payload.postalcode = this.companyAddress.PostalCode
            payload.city = this.companyAddress.City ?? ''
            payload.country = this.companyAddress.Country
          } else {
            if (this.driverAdr) {
              payload.street = this.driverAdr.street
              payload.housenumber = this.driverAdr.housenumber
              payload.postalcode = this.driverAdr.postalcode
              payload.city = this.driverAdr.city ?? ''
              payload.country = this.driverAdr.country
            }
          }
        }
        await new RequestApi().createChargePassRequest(payload)
        this.isSaved = true
        this.isSaveRequest = false
        this.isSaving = false
        this.$emit('refresh')
      }
    } catch (error) {
      this.isSaveRequest = false
      this.isSaving = false
    }
  }

  previousStep() {
    this.cleanPayload()
    this.$emit('back')
  }

  resetErrors() {
    this.error = false
  }

  cancel() {
    this.$emit('cancel')
  }

  get checkIfFleetManager() {
    this.fetchDefaultUserAddress()
    return this.checkIfAllowed()
  }

  checkIfAllowed() {
    if (
      this.usrDTA &&
      this.usrDTA.role &&
      this.usrDTA.Relation &&
      this.usrDTA.Relation.IsCompany
    ) {
      const isAllowedToSeeInput =
        this.usrDTA?.role?.indexOf('EntityManager') > -1
      const isPartOfACompany = this.usrDTA.Relation?.IsCompany > 0
      return isAllowedToSeeInput && isPartOfACompany
    }
  }

  get defaultSelectedRelation() {
    if (
      this.relationCompany === '' &&
      this.usrDTA.Relation &&
      this.usrDTA.Relation.Id
    ) {
      this.relationCompany = this.usrDTA.Relation.Id
    }
    return this.relationCompany
  }

  set defaultSelectedRelation(val) {
    this.relationCompany = val
  }

  get defaultSelectedRelationContact() {
    if (
      !this.relationContactId &&
      !this.checkIfAllowed &&
      this.usrDTA &&
      this.usrDTA.RelationContact &&
      this.usrDTA.RelationContact.Id
    ) {
      this.relationContactId = this.usrDTA.RelationContact?.Id
    }
    return this.relationContactId
  }

  set defaultSelectedRelationContact(val) {
    this.relationContactId = val
  }

  fetchDefaultUserAddress() {
    if (!this.checkIfAllowed() && this.usrDTA && this.usrDTA.RelationContact) {
      this.driverAdr.street = this.usrDTA.RelationContact.Street
      this.driverAdr.housenumber = this.usrDTA.RelationContact.HouseNumber
      this.driverAdr.postalcode = this.usrDTA.RelationContact.PostalCode
      this.driverAdr.city = this.usrDTA.RelationContact.City
      this.driverAdr.country = this.usrDTA.RelationContact.Country
    }
  }

  async fetchCompanyAddress(val: string) {
    try {
      const dt: CompanyApiAddressInterface = (await new RelationApi(
        0
      ).getRelationById(val)) as CompanyApiAddressInterface
      this.companyAddress = {
        Street: dt.data.Street,
        HouseNumber: dt.data.HouseNumber,
        PostalCode: dt.data.PostalCode,
        City: dt.data.City,
        Country: dt.data.Country
      }
    } catch (error) {
      console.log(error)
    }
  }

  async fetchRelationContacts(isAllDrivers = false) {
    this.isLoading = true
    try {
      const data: RelationContactApiInterface =
        (await new RelationContactApi().myRelationContacts(
          String(this.relationCompany),
          !isAllDrivers
        )) as RelationContactApiInterface
      this.userTab = data.data
    } catch (error) {
      console.log(error)
    }
    this.isLoading = false
  }

  resetCompanyAddress() {
    this.companyAddress = INITIAL_COMPANY_ADDRESS_VALUE
  }

  resetDriverAddress() {
    this.relationContactId = ''
    this.driverAdr = INITIAL_DRIVER_ADDRESS_VALUE
  }

  get checkAddress() {
    if (this.isOtherAddress) {
      if (this.relationContactId === '' && this.checkIfAllowed()) {
        return !this.checkIfEmpty(this.companyAddress)
      } else {
        return !this.checkIfEmpty(this.driverAdr)
      }
    }
  }

  @Watch('relationContactId')
  relationContactIdOnChanged(val: number) {
    if (this.relationContactId === null) {
      this.resetDriverAddress()
    }
    if (this.checkIfAllowed()) {
      this.userTab.find((o: RelationContactInterface) => {
        if (o && o.address && o.id === val) {
          this.driverAdr = o.address
        }
      })
    } else {
      if (this.usrDTA && this.usrDTA.RelationContact) {
        this.driverAdr.street = this.usrDTA.RelationContact.street
        this.driverAdr.housenumber = this.usrDTA.RelationContact.housenumber
        this.driverAdr.postalcode = this.usrDTA.RelationContact.postalcode
        this.driverAdr.city = this.usrDTA.RelationContact.city
        this.driverAdr.country = this.usrDTA.RelationContact.country
      }
    }
  }

  @Watch('relationCompany')
  relationCompanyOnChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.resetDriverAddress()
      this.resetCompanyAddress()
      this.fetchCompanyAddress(val)
      this.fetchRelationContacts(true)
    }
  }
}
