export interface ValidValues {
  label: string
  objectKey: string
  linkedvalue: string
}

export interface JiraRequestField {
  name: string
  fieldid: string
  dta: string
  validvalues: ValidValues[]
}

interface JiraRequestFieldResponse {
  data: JiraRequestField[]
}
export interface JiraRequestFieldApi {
  result: JiraRequestFieldResponse
}

export interface JiraRequestType {
  id: string
  name: string
}
export interface JiraFieldTypeReponse {
  data: JiraRequestType[]
}

export interface JiraFieldTypeApi {
  result: JiraFieldTypeReponse
}

export interface PayloadType {
  [key: string]: { key: string | undefined }[] | string
}

interface RouterTo {
  name: string
}
export interface BreadCrumbType {
  text?: string
  disabled?: boolean
  href?: string
  to?: RouterTo
  last?: boolean
}

interface SupportRequestContact {
  firstname: string
  lastname: string
  name: string
  email: string
}
interface SupportRequestComment {
  body: string
  sourceid: string
  created: string
  contact: SupportRequestContact
}

interface AttachmentRequest {
  id?: string
  sourceid?: string
  filename: string
  filesize: number
  contract?: SupportRequestContact
}

export interface SupportRequestDetail {
  sourceid: string
  sourcereference: string
  title: string
  body: string
  type: string
  status: string
  updatedon: string
  createdon: string
  contact: SupportRequestContact
  comments: SupportRequestComment[]
  attachments?: AttachmentRequest[]
}

export interface TemplateInterface {
  label: string
  type: number
}

export interface AttachmentResponseInterface {
  headers: AttachmentRequest
  data: Blob
}

export interface TemplateItem {
  type: string
  label: string
  data: string
}

export interface TemplateType {
  type: string
  label: string
  items?: string[]
  data: string
  rules: string
}

const templateReimbursement = [
  {
    type: 'selectbox',
    label: 'Type of reimbursement:',
    items: ['CREG tariff', 'Fixed tariff'],
    data: '',
    rules: ['required']
  },
  {
    type: 'input_eur',
    label: 'EUR/Kwh',
    data: '',
    visibiliy: false,
    rules: ['required']
  },
  {
    type: 'datepicker',
    label: 'Start date reimbursement (at least 10 work days from now) :',
    data: '',
    visibiliy: false,
    rules: ['required']
  },
  {
    type: 'iban',
    label: 'Bank account number:',
    data: '',
    rules: ['required']
  }
]

const templateOldDriver = [
  {
    type: 'title',
    label: 'Old driver:\n\n',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Last name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'First name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'E-mail:',
    data: '',
    rules: ['rulesEmail']
  }
]

const templateNewDriver = [
  {
    type: 'title',
    label: 'New driver:\n\n',
    data: ''
  },
  {
    type: 'input',
    label: 'Name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',

    label: 'First name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Address:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Zip:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'City:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Phone:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'E-mail:',
    data: '',
    rules: ['rulesEmail']
  },
  {
    type: 'input',
    label: 'Invoice Reference:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Employer:',
    data: '',
    rules: ['required']
  }
]

const templateIsKnownPortal = [
  {
    type: 'input',
    label: 'Fleetmanager first name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'Fleetmanager last name:',
    data: '',
    rules: ['required']
  },
  {
    type: 'input',
    label: 'E-mail address fleetmanager:',
    data: '',
    rules: ['required']
  }
]

export function defaultTemplateData() {
  return [
    {
      template: templateOldDriver,
      visibility: true
    },
    {
      template: templateNewDriver,
      visibility: true
    },
    {
      template: templateReimbursement,
      showCheckbox: true,
      checkboxLabel: 'Reimbursement :',
      visibility: false
    },
    {
      template: templateIsKnownPortal,
      showCheckbox: true,
      checkboxLabel: 'The employer does not yet have an EVA account ?',
      visibility: false
    }
  ]
}
