var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm._.isEmpty(_vm.userDTA.data))?_c('div',{staticClass:"mt-4 ml-10 mr-10"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',[_c('h1',{staticClass:"text-h4 toolbar__title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.t('$vuetify.serviceCenter.title'))+" ")])]):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-spacer'):_vm._e(),_c('AutocompleteStateRequest',{staticClass:"pr-5 pr-md-0 mt-1 mb-1 ml-0 mr-0",attrs:{"selected":_vm.search},on:{"update:selected":function($event){_vm.search=$event}}}),(_vm.userDTA.data.role !== 'User')?_c('v-select',{class:{
      'mx-2': _vm.$vuetify.breakpoint.mdAndUp,
      'pr-5 pr-md-0': !_vm.$vuetify.breakpoint.mdAndUp
    },staticStyle:{"margin":"5px 0 5px 0"},attrs:{"items":_vm.itemIssues,"label":"Status","item-text":"txt","item-value":"val","single-line":"","hide-details":"","append-icon":"uil uil-search","outlined":"","dense":""},model:{value:(_vm.issueType),callback:function ($$v) {_vm.issueType=$$v},expression:"issueType"}}):_vm._e(),(_vm.userDTA.data.RelationContact.Email !== '')?_c('v-btn',{staticClass:"white--text pa-5",staticStyle:{"border-radius":"4px","opacity":"1","margin":"5px 0 5px 0"},attrs:{"color":"primary","elevation":"0","id":"new-request"},on:{"click":function($event){_vm.dialog2 = true}}},[_vm._v(" "+_vm._s(_vm.t('$vuetify.serviceCenter.newRequest'))+" ")]):_vm._e(),(_vm.userDTA.data.RelationContact.Email === '')?_c('span',{staticClass:"red--text"},[_vm._v(" No requests can be made as long as the user doesn't have an email. ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","loading":_vm.loading,"loading-text":_vm.t('$vuetify.myOrganisation.loadingWait'),"id":"request-table","page":_vm.page,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"pagination":function($event){_vm.pagination = $event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.xs)?{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-card',{staticClass:"mb-2",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"2"},on:{"click":function($event){return _vm.getDetailPage(item)}}},[_c('v-card-title',[(item.type === 'Email')?_c('v-icon',{staticClass:"pr-1",attrs:{"size":"18"}},[_vm._v(" mdi-email ")]):_vm._e(),(item.type === 'Charge Pass')?_c('v-icon',{staticClass:"uil uil-credit-card",attrs:{"size":"21"}}):_vm._e(),_vm._v(" "+_vm._s(item.key)+" "),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColorStatus(item.status),"small":"","label":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1),_c('v-card-text',{staticClass:"pa-5"},[_vm._v(" "+_vm._s(item.summary)+" "),(item.attachment && item.attachment > 0)?_c('v-badge',{attrs:{"content":item.attachment,"color":"grey","overlap":""}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" uil-paperclip ")])],1):_vm._e(),_vm._v(" "),_c('br'),_c('br'),_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(item.requester)+" "),_c('br'),_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-calendar")]),_vm._v(" "+_vm._s(item.created)+" ")],1)],1)]}}:{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.getDetailPage(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.type)+" ")]),_c('td',[_vm._v(_vm._s(item.key))]),_c('td',[_vm._v(" "+_vm._s(item.summary)+" "),(item.attachment && item.attachment > 0)?_c('v-badge',{attrs:{"content":item.attachment,"color":"grey","overlap":""}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" uil-paperclip ")])],1):_vm._e()],1),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColorStatus(item.status),"small":"","label":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1),_c('td',[_vm._v(_vm._s(item.requester))]),_c('td',[_vm._v(_vm._s(item.created))])])]}}],null,true)}),(_vm.data.length > 0)?_c('div',{staticClass:"text-right mt-5 mb-5"},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"white","disabled":_vm.pagination.page === 1,"aria-label":"previous page button"},on:{"click":function($event){_vm.page--}}},[_c('v-icon',{staticClass:"uil uil-arrow-left",attrs:{"dark":""}})],1),_c('span',{staticClass:"mx-5"},[_vm._v(_vm._s(_vm.pagination.page)+" of "+_vm._s(_vm.pagination.pageCount))]),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"white","disabled":!(_vm.pagination.page < _vm.pagination.pageCount),"aria-label":"next page button"},on:{"click":function($event){_vm.page++}}},[_c('v-icon',{staticClass:"uil uil-arrow-right",attrs:{"dark":""}})],1)],1):_vm._e(),[_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"750px"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[(_vm.selectedItem !== 0 && _vm.selectedItem !== 1 && _vm.selectedItem !== 2
          )?_c('v-card',{staticClass:"pt-4"},[(_vm.selectedItem >= 0 && _vm.selectedItem !== 4)?_c('v-card-title',[_c('v-icon',{staticClass:"pr-2",attrs:{"large":"","color":_vm.requestTitle.color}},[_vm._v(_vm._s(_vm.requestTitle.icon))]),_vm._v(" "+_vm._s(_vm.requestTitle.text)+" ")],1):_vm._e(),_c('v-card-text',[(_vm.selectedItem === -1 || _vm.selectedItem === 4)?_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[(!_vm._.isEmpty(_vm.userDTA.data))?_c('v-list-item',{attrs:{"id":"chargepass-request"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("$vuetify.icons.cardQuestionMark")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('$vuetify.serviceCenter.requestChargeCard'))+".")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"id":"other-request"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":""}},[_vm._v("$vuetify.icons.help")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('$vuetify.serviceCenter.otherReq'))+".")])],1)],1)],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1):(_vm.selectedItem === 0 && !_vm._.isEmpty(_vm.userDTA.data))?[(!_vm._.isEmpty(_vm.userDTA.data))?_c('charge-pass-request',{attrs:{"usrDTA":_vm.userDTA.data},on:{"back":function($event){_vm.selectedItem = -1},"cancel":_vm.cancel,"refresh":function($event){return _vm.refresh()}}}):_vm._e()]:(_vm.selectedItem === 1 ||
          (!_vm._.isEmpty(_vm.userDTA.data) &&
            _vm.userDTA.data.role === 'User' &&
            _vm.selectedItem === 0)
          )?[_c('generic-support',{on:{"back":function($event){_vm.selectedItem = -1},"cancel":_vm.cancel,"refresh":function($event){return _vm.refresh()}}})]:_vm._e()],2)],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }