
















import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class AutocompleteTokenList extends Vue {
  @PropSync('selected', { type: String }) selectedSynced!: string

  search = ''

  itemResolution = [
    { text: this.t('$vuetify.serviceCenter.anyStatus'), value: '' },
    { text: this.t('$vuetify.serviceCenter.openRequest'), value: 'To Do' },
    { text: this.t('$vuetify.serviceCenter.closedRequest'), value: 'Done' }
  ]
}
