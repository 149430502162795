export const removeSpaces = (value: string) => {
  return value.trim().replace(/\s+/g, '')
}

export const includeVatText = (
  text: string,
  isExemptFromVat: number
): string => {
  if (isExemptFromVat === 1) {
    return text
  }
  return `${text} excl VAT`
}
