






















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
import { isEmail, isRequired } from '@bluecorner/shared'
import IbanField from '@/components/common/IbanField.vue'
import moment from 'moment'

import {
  isMaxLengthCheck
} from '@/functions/formRules'
import { TemplateType } from '@/typings/ApiTypings/JiraSupportInterface'

@Component({
  components: {
    IbanField
  }
})
export default class GenericTemplate extends Vue {
  @PropSync('templateTab', { type: Array }) templateIn!: TemplateType
  rules = {
    required: [isRequired()],
    rulesEmail: [
      isRequired('This field is required'),
      isMaxLengthCheck('Maximum no. of characters allowed is 240', 240),
      isEmail('Invalid Email address')
    ]
  }

  get minimumDate() {
    const today = moment();
    let workdays = 0;

    while (workdays < 10) {
      today.add(1, 'days');

      if (today.day() !== 0 && today.day() !== 6) {
        workdays++;
      }
    }
    return today.format('YYYY-MM-DD')
  }
}
